import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const MoreButton = ({
  color,
  textColor,
  borderWidth,
  href,
  target,
  onClick,
  style,
  children,
}) => {
  const classes = useStyles({ color, textColor, borderWidth });

  return (
    <Button
      variant="outlined"
      className={classes.root}
      href={href}
      target={target}
      onClick={onClick}
      style={style}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: ({ color, textColor }) => textColor || color,
    border: ({ color, borderWidth }) =>
      `${borderWidth || "1px"} solid ${color}`,
    borderRadius: "0px",
  },
}));

export default MoreButton;
