import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import Container from "../components/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Layout from "../components/layout";
import Banner from "../components/Banner";

import useLanguage from "../hooks/useLanguage";
import useUrl from "../hooks/useUrl";

import i18nContext from "../i18n-context";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import SubscribeForm from "../components/SubscribeForm";

const SubscribePage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const classes = useStyles();
  const i18n = useContext(i18nContext)[lang];

  const {
    title: bannerTitle,
    excerept: description = "",
    // content: mailchimp,
    translated,
  } = pageContext;
  const bannerUrl = pageContext.featuredImage?.node?.mediaItemUrl || "";

  let mailchimpSrc = "/subscribe_form.html";
  if (lang === "tc") mailchimpSrc = "/subscribe_form_tc.html";
  if (lang === "sc") mailchimpSrc = "/subscribe_form_sc.html";

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${bannerTitle}`}</title>
        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={bannerTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={bannerUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={bannerTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={bannerUrl} />
      </Helmet>

      <Banner title={bannerTitle} bannerUrl={bannerUrl} />

      <Container className={classes.container}>
        {description && (
          <Typography variant="h4" className={classes.description}>
            {parse(description)}
          </Typography>
        )}
        {/* <iframe
          title="mailchimp"
          src={mailchimpSrc}
          className={classes.mailchimp}
        /> */}
        <MailchimpSubscribe
          url="https://fbicgroup.us9.list-manage.com/subscribe/post?u=c02ff8c7ae0a54bad5b7fac53&amp;id=3a597fb729"
          render={({ subscribe, status, message }) => (
            <SubscribeForm
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
            />
          )}
        />
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },

  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  mailchimp: {
    width: "100%",
    borderWidth: 0,
    height: "100vh",

    [theme.breakpoints.down("xs")]: {
      height: "150vh",
    },
  },
}));

// $locale: String
export const query = graphql`
  query subscribePageQuery {
    wp {
      homeCategories {
        language
        name
      }
    }
  }
`;

export default SubscribePage;
