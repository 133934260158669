import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import MoreButton from "./MoreButton";
import { makeStyles } from "@material-ui/core";

import i18nContext from "../i18n-context";

const SubscribeForm = ({ status, message, onValidated }) => {
  const i18n = useContext(i18nContext);
  const classes = useStyles();
  const { spacing, palette } = useTheme();
  const [error, setError] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [formData, setFormData] = useState({
    FIRSTNAME: "",
    LASTNAME: "",
    EMAIL: "",
    JOBTITLE: "",
    COMPANY: "",
  });

  useEffect(() => {
    if (status === "success") clearForm();
  }, [status]);

  const fields = [
    {
      heading: i18n.subscribeForm.firstName,
      id: "FIRSTNAME",
      name: "FIRSTNAME",
    },
    {
      heading: i18n.subscribeForm.lastName,
      id: "LASTNAME",
      name: "LASTNAME",
    },
    {
      heading: i18n.subscribeForm.email,
      id: "EMAIL",
      name: "EMAIL",
    },
    {
      heading: i18n.subscribeForm.jobTitle,
      id: "JOBTITLE",
      name: "JOBTITLE",
    },
    {
      heading: i18n.subscribeForm.company,
      id: "COMPANY",
      name: "COMPANY",
    },
  ];

  const areaOfInterest = [
    {
      input: "group[33][1]:1",
      label: i18n.subscribeForm.businessModelInnovationAndTechnology,
    },
    { input: "group[33][2]:2", label: i18n.subscribeForm.logistics },
    { input: "group[33][4]:4", label: i18n.subscribeForm.retailAndEcommerce },
    { input: "group[33][8]:8", label: i18n.subscribeForm.sourcing },
    { input: "group[33][32]:32", label: i18n.subscribeForm.supplyChain },
    { input: "group[33][16]:16", label: i18n.subscribeForm.tradeAndEconomy },
  ];

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    });
  };

  const handleBoxCheck = (input) => {
    const [key, val] = input.split(":");
    let updatedFormData;
    if (Boolean(formData[key])) {
      updatedFormData = { ...formData };
      delete updatedFormData[key];
    } else {
      updatedFormData = { ...formData, [key]: val };
    }
    setFormData(updatedFormData);
  };

  const handleSubmit = (e) => {
    setError(true);
    e.preventDefault();
    if (!Object.values(formData).every((val) => Boolean(val))) return;
    if (formData["EMAIL"].indexOf("@") <= -1) {
      setInvalidEmail(true);
      return;
    }
    onValidated({ ...formData });
  };

  const clearForm = () => {
    setError(false);
    setFormData({
      FIRSTNAME: "",
      LASTNAME: "",
      EMAIL: "",
      JOBTITLE: "",
      COMPANY: "",
    });
  };

  return (
    <div style={{ paddingTop: spacing(4), paddingBottom: spacing(6) }}>
      <Typography variant="h4" paragraph style={{ fontFamily: "Open Sans" }}>
        {i18n.subscribeForm.subscribe}
      </Typography>

      <form>
        <Grid container direction="column" spacing={2}>
          {fields.map((field) => {
            return (
              <Grid item key={field.id}>
                <Typography>
                  {field.heading}{" "}
                  <span style={{ color: palette.error.main }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  value={formData[field.id]}
                  id={field.id}
                  name={field.name}
                  helperText={
                    error &&
                    ((!formData[field.id] && "required") ||
                      (field.name === "EMAIL" &&
                        invalidEmail &&
                        "invalid email address"))
                  }
                  variant="outlined"
                  margin="dense"
                  error={
                    error &&
                    (!formData[field.id] ||
                      (field.name === "EMAIL" && invalidEmail))
                  }
                  onChange={handleInput}
                />
              </Grid>
            );
          })}
          <Grid item>
            <Typography
              variant="h5"
              paragraph
              style={{ fontFamily: "Open Sans", marginTop: spacing(2) }}
            >
              {i18n.subscribeForm.areaOfInterest}
            </Typography>
            <FormGroup>
              {areaOfInterest.map((item) => {
                // console.log(item.input);
                return (
                  <FormControlLabel
                    key={item.label}
                    control={
                      <Checkbox
                        checked={Boolean(formData[item.input.split(":")[0]])}
                        onChange={() => handleBoxCheck(item.input)}
                        name={item.label}
                      />
                    }
                    label={item.label}
                  />
                );
              })}
            </FormGroup>
          </Grid>
        </Grid>
        <div>
        {status === "sending" && (
          <Typography className={classes.responseText}>
            {i18n.subscribeForm.sending}
          </Typography>
        )}
        {status === "success" && (
          <Typography className={`${classes.green} ${classes.responseText}`}>
            {i18n.subscribeForm.success}
          </Typography>
        )}
        {status === "error" && (
          <Typography
            component="div"
            className ={`${classes.res} ${classes.responseText}`}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
        <MoreButton
          onClick={handleSubmit}
          color={palette.error.main}
          textColor={palette.primary.main}
          style={{ marginTop: spacing(4) }}
        >
          {i18n.subscribeForm.submit}
        </MoreButton>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  responseText:{
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  green:{
    color: theme.palette.success.main
  },
  red:{
    color: theme.palette.error.main
  }
}));

export default SubscribeForm;
